<template>
  <view v-if="info" class="detail" :style="{ paddingTop: paddingTop }">
    <view class="u-flex-c">
      <view v-if="info.auctionType === 'BLIND'" class="blind H6">暗拍</view>

      <view class="name H3 otext2">{{ info.fieldName }}</view>
    </view>
    <view class="state">
      <view class="u-flex-c shrink-0">
        <text class="N5">{{ info.auctionCount }}</text>
        <text class="H6">件拍品</text>
      </view>
      <DuDivider type="vertical" length="11px" />
      <view class="time before">
        <SessionTime
          :today="new Date().getTime()"
          :item="{
            startTime: +info.startTime,
            earliest: info.earliestAt * 1000,
          }"
        />
      </view>
    </view>
    <view class="description B6" v-if="info.description">
      <span>{{ info.description }}</span>
    </view>
    <view v-if="info?.listPermissionType === 'WL_INTERNAL_GROUP'">
      <view class="wl_internal_group B7">
        <DuIcon name="people" size='3.2rem' />
        <view>本拍场仅限指定用户可发布拍品</view>
      </view>
    </view>
  </view>
</template>

<script setup>
import { defineProps } from "vue";
import dayjs from "dayjs";
dayjs.locale("zh-cn");
import { DuIcon, DuDivider } from "dangoui";
import SessionTime from "./session-time.vue";

import { computed } from "vue";
defineProps({
  info: {
    type: Object,
    default: () => null,
  },
});
const paddingTop = computed(() => {
  const navHeight = 84;

  return `calc(${navHeight}px + 30rpx)`;
});
</script>

<style lang="scss">
.shrink-0 {
  flex-shrink: 0;
}
.u-flex-c {
  display: flex;
  align-items: center;
}
.detail {
  display: flex;
  flex-direction: column;
  gap: 16rpx;
  width: 570rpx;
  padding: 24rpx 30rpx;
  .name {
    width: 100%;
  }
  .description {
    white-space: pre-wrap;
    color: rgba(0, 0, 0, 0.64);
  }
  .wl_internal_group {
    gap: 8rpx;
    border-radius: 8rpx;
    padding: 8rpx;
    color: rgba(0, 0, 0, 0.4);
    display: inline-flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.24);
  }
  .blind {
    flex-shrink: 0;
    margin-right: 16rpx;
    padding: 4rpx;

    color: #fff;

    background: var(--mask-secondary, rgba(0, 0, 0, 0.64));

    border-radius: 4rpx;
  }
  .state {
    display: flex;
    align-items: center;
    gap: 8rpx;
  }
  .follow-button {
    width: 240rpx;
  }
}
</style>
