<template>
  <view :class="['countdown', hasClass ? status : '']">
    <view class="B6">{{ text }}</view>
    <view class="N6">{{ countdown }}</view>
    <view v-if="status === 'waiting'" class="B6">开拍</view>
  </view>
</template>

<script>
import dayjs from "dayjs";
import { time } from "@frontend/echo-utils";
import calendar from "dayjs/plugin/calendar";
dayjs.extend(calendar);
dayjs.locale("zh-cn");
const TIME_LIMIT = 3600; // 剩余 60 分钟才开始倒计时
export default {
  unmounted() {
    if (this.interval) clearInterval(this.interval);
  },

  props: {
    finishTime: {
      type: String,
      required: true,
    },
    startTime: {
      type: Number,
      default: 0,
    },
    hasClass: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    this.interval = null;

    return {
      TIME_LIMIT,
      offset: 0,
      countdown: "",
      status: "default",
      text: "距截拍",
    };
  },

  computed: {
    endTime() {
      //TODO: 包裹的 try catch 是为了兼容 uniapp v2，应该在后续删除
      try {
        return dayjs.unix(this.finishTime);
      } catch (e) {
        return null;
      }
    },
    isShow() {
      return Math.abs(this.offset);
    },
  },

  mounted() {
    clearInterval(this.interval);
    this.computeOffset();

    if (this.offset >= 0) {
      this.interval = setInterval(this.computeOffset, 1000);
    }
  },

  methods: {
    getDay(time) {
      return dayjs(+time).calendar(null, {
        sameDay: "[今天] HH:mm",
        nextWeek: "MM-DD HH:mm",
        nextDay: "[明天] HH:mm",
        lastDay: "MM-DD HH:mm",
        lastWeek: "MM-DD HH:mm",
        sameElse: "MM-DD HH:mm",
      });
    },
    computeOffset() {
      if (!this.finishTime) return;
      this.offset = this.endTime.diff(dayjs(), "second");
      const today = new Date().getTime();
      const start = this.startTime * 1000;
      if (start > today) {
        this.countdown = `${this.getDay(start)}`;
        this.text = "";
        this.status = "waiting";

        return;
      }
      if (this.offset >= 0) {
        let format = "m分s秒";
        let text = "距截拍";
        if (this.offset > 86400) {
          format = "D天H时";
        } else if (this.offset > 3600) {
          format = "H时m分";
        }
        if (TIME_LIMIT > this.offset) {
          this.status = "waring";
          text = "即将截拍";
        }
        this.text = text;
        // : this.offset - TIME_LIMIT <= 0 ? 'H时m分s秒' : 'D天H时m分',
        this.countdown = time.offset({
          sourceTime: this.endTime.valueOf(),
          format,
        });
        if (this.offset <= 0) {
          clearInterval(this.interval);
        }
      } else if (this.offset < 0) {
        this.text = "截拍中";
        this.countdown = "";
      }
    },
  },
};
</script>

<style lang="scss">
.countdown {
  height: 44rpx;
  gap: 4rpx;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.countdown.waiting {
  color: #000;

  background: #e2f7e3;
}
.countdown.default {
  color: #fff;

  background: rgba(0, 0, 0, 0.64);
}
.countdown.waring {
  color: #f96464;

  background: #ffebe9;
}
</style>
