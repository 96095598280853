import { cloneDeep, omitBy } from "@frontend/echo-utils";

const auctionTypeKey = {
  opening: "OPEN",
  blind: "BLIND",
};
function updateAuction(item) {
  if (item.auction_id) {
    const res = cloneDeep(item);
    const {
      description,
      auction_id,
      cover,
      medias,
      seller,
      auction_field,
      status,
      real_spu_name,
      isJoin,
      isLeader,
      auction_type,
      hammer_price,
      requestId,
    } = res;
    item = omitBy(
      {
        requestId,
        description,
        id: auction_id,
        isLeader,
        isJoin,
        cover,
        hammerPrice: hammer_price,
        type: auctionTypeKey[auction_type],
        medias,
        startingPrice: res.starting_price,
        finishTime: res.finish_time,
        startTime: res.start_time,
        currentPrice: res.current_price,
        currentBidsCount: res.current_bidders,
        followCount: res.follow_count,
        seller: seller
          ? {
              avatar: seller.avatar,
              id: seller.user_id,
              nickname: seller.nickname,
            }
          : null,
        field: auction_field ? auction_field : null,
        status,
        spu: real_spu_name ? { name: real_spu_name } : null,
      },
      (v) => v === null || v === undefined
    );
  }
  item.finishTime =
    item.finishTime?.length === 13 ? item.finishTime / 1000 : +item.finishTime;
  item.startTime =
    item.startTime?.length === 13 ? item.startTime / 1000 : +item.startTime;
  return item;
}
export default {
  updateAuction,
};
