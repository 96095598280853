<template>
  <view class="session-time u-flex-c" :style="{ color: color }">
    <text v-if="today > item.startTime">已开拍，</text>
    <text
      v-if="item.startTime > today"
      :style="{ fontSize: fontSize }"
      class="font-roboto-bold-500"
    >
      {{ getDay(item.startTime) }}
    </text>
    <text v-if="item.startTime > today">开拍，</text>

    <text :style="{ fontSize: fontSize }" class="font-roboto-bold-500">
      {{ getDay(item.earliest) }}
    </text>
    <text>逐件截拍</text>
  </view>
</template>

<script setup>
import { defineProps } from "vue";

import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
dayjs.extend(calendar);
dayjs.locale("zh-cn");

defineProps({
  item: {
    type: Object,
    default: null,
  },
  color: {
    type: String,
    default: "",
  },
  fontSize: {
    type: String,
    default: '3.733333333333334rem',
  },
  today: {
    type: Number,
    default: 0,
  },
});
function getDay(time) {
  return dayjs(+time).calendar(null, {
    sameDay: "[今天] HH:mm",
    nextWeek: "MM-DD HH:mm",
    nextDay: "[明天] HH:mm",
    lastDay: "MM-DD HH:mm",
    lastWeek: "MM-DD HH:mm",
    sameElse: "MM-DD HH:mm",
  });
}
</script>

<style lang="scss">
.session-time {
  font-weight: 500;
  font-size: 24rpx;
  flex-shrink: 0;
  line-height: 36rpx;
  text {
    flex-shrink: 0;
  }
  .font-roboto-bold-500 {
    margin: 0 4rpx;

    font-size: 28rpx;
  }
}
</style>
